@charset "utf-8";
@import (reference) "css3-mixin";

.base-font(){
    font-family: 'Nanum Barun Gothic', 'Apple SD Gothic Neo', 'Nanum Gothic', sans-serif;
    font-weight:400;line-height:1.2;letter-spacing:0;
}

.width-full {
    width: 100% !important;

    &.child-center {
        & > * {
            margin: auto;
        }
    }
}

.width-auto {
    width: auto !important;
}

.sub-width {
    width: 1100px;
}

.float-clear() {
    &:after {
        content: '';
        display: block;
        clear: both;
    }
}

.base-width() {
    max-width: 1626px;
    min-width: 1220px;
}

.margin-gnb {
    margin-top: 150px;
}

.padding-gnb {
    padding-top: 150px;
}

//body {
//    overflow: hidden;
//}

// center 정렬
.dtaq_content {
  margin: 0 auto 35px;
}
.dtaq_location {
    margin: auto;
}
.jwxe_root.dtaq_board {
    margin: auto;
    left: 0;
}

// GNB
header.gnb {
    position: fixed;
    z-index: 1000;
    left: 0;
    right: 0;
    background: #fff;
    height: 130px;

    &.scroll {
        -webkit-box-shadow: 0px -10px 60px -6px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px -10px 60px -6px rgba(0,0,0,0.75);
        box-shadow: 0px -10px 60px -6px rgba(0,0,0,0.75);

        opacity: .95;
    }

    .base-font();

    &.over {
        border-bottom: 1px solid #000;

        .sub-menu {
            display: inline-block !important;
        }
    }

    .gnb-wrap {
        position: relative;
        //width: 1626px;
        .base-width();
        margin: auto;

        .float-clear();
    }

    .box-sizing();
    .float-clear();

    .left {
        float: left;
        a.logo {
            display: inline-block;
            margin-top: 75px;
            padding-left: 10px;
        }
    }

    .right {
        float: right;
        padding-right: 10px;
        .buttons {
            margin-top: 43px;
            text-align: right;
            .search-input {
                display: none;
                position: relative;
                vertical-align: middle;
                margin-right: -30px;
                padding: 3px 30px 3px 8px;
                border: 1px solid #aaaaaa;
                z-index: 1;
                height: 25px;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;
                .border-radius(8px);

                input {
                    border: 0;
                    outline: none;
                }
            }
            .btn-search {
                position: relative;
                z-index: 2;

                //&.over {
                //    img {
                //        width: 15px;
                //        height: 15px;
                //        .transition(all 10s ease-in-out);
                //    }
                //}
            }
            .select-type1 {
                text-align: left;
                display: inline-block;
            }
            .site {
                width: 153px;
                margin-left: 13px;

                .list {
                    width: 153px !important;
                }
            }
            .lang {
                width: 58px;
                margin-left: 8px;
            }
        }
        .menu {
            margin-top: 22px;
            .float-clear();
            & > li {
                position: relative;
                float: left;
                width: 140px;

                //& + li {
                //    margin-left: 53px;
                //}

                a {
                    display: block;
                    font-size: 18px;
                    font-size-adjust: 0.5;
                }
            }

            .btn-menu {
                width: auto;

                .btn-sitemap-show {
                    display: block;
                }
                .btn-sitemap-close {
                    display: none;
                }
            }
        }

        .sub-menu {
            position: absolute;
            top: 60px;
            left: 0;
            width: 120px;
            display: none;
            li {
                display: block;
                a {
                    font-size: 13px;
                }
                &+li {
                    margin-top: 10px;
                }
            }
        }
    }

    .sitemap {
        display: none;
        position: absolute;
        left: 0;
        top: 35px;
        background: #fff;
        border: 1px solid #bcbcbc;

        .float-clear();

        & > ul {
            width: 203px;
            float: left;
            min-height: 530px;

            & + ul {
                border-left: 1px solid #bcbcbc;
            }

            li {
                font-size: 15px;
                font-weight: 400;
                padding: 0 15px;

                .box-sizing();

                &.title {
                    font-size: 16px;
                    color: #1f5fa6;
                    font-weight: bold;
                    text-align: center;
                    padding: 12px 0;
                    background: #ebebeb;

                    & + li a {
                        border-top: 0;
                    }
                }

                a {
                    display: block;
                    padding: 12px 0 12px 30px;

                    //&.sub-title {
                    //    border-bottom: 0;
                    //    padding-bottom: 10px;
                    //}
                }

                &+li {
                    a {
                        border-top: 1px solid #bcbcbc;

                        //&.sub-title {
                        //    border-bottom: 0;
                        //    padding-bottom: 10px;
                        //}
                    }
                }

                // 2뎁스 메뉴
                ul {
                    margin-top: -2px;
                    li {
                        padding: 0;
                        a {
                            position: relative;
                            padding: 0 0 0 40px;
                            border-top: 0;
                            font-size: 13px;
                            &:before {
                                content:' ';
                                position: absolute;
                                width: 3px;
                                height: 3px;
                                top: 50%;
                                margin-top: -2px;
                                left: 30px;
                                background: #000;
                                .border-radius(50%);
                            }

                        }

                        &:last-of-type {
                            a {
                                margin-bottom: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
}

section.sub-gnb {
    background: #fff;
    //padding-top: 20px;
    padding-top: 130px;
    border-bottom: 1px solid #e2e2e2;

    .bg {
        position: relative;
        width: 100%;
        height: 255px;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        p.title {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            text-align: center;
            font-size: 47px;
            color: #fff;
            font-weight: bold;
            line-height: 270px;
        }
    }

    a {
      .base-font();
    }

    .menu-wrap {
        .sub-width();
        margin: auto;
        .menu {
            & > ul {
                width: 100%;
                height: 50px;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;
                display: table;
                & > li {
                    position: relative;
                    text-align: center;
                    display: table-cell;
                    padding: 20px 0;

                    //&:before {
                    //    content:' ';
                    //    position: absolute;
                    //    bottom: 0;
                    //    left: 0;
                    //    width: 100%;
                    //    height: 1px;
                    //    background: #e2e2e2;
                    //}
                    &:after {
                        content:' ';
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 0;
                        height: 2px;
                        background: #147ac5;
                    }

                    &.on {
                        &:after {
                            width: 100%;
                            .transition(all 300ms ease-in-out);
                        }
                    }
                    &:hover {
                        &:after {
                            width: 100%;
                            .transition(all 300ms ease-in-out);
                        }
                    }

                    a {
                        position: relative;
                        display: block;
                        font-size: 17px;
                        vertical-align: middle;

                        .icon-arrow{
                            display:inline-block;
                            width: 14px;
                            height: 8px;
                            vertical-align: middle;
                            background: transparent url("../img/sub/sub-arrow.png") no-repeat;
                            margin-left: 15px;
                            .transition(all 300ms ease-in-out);

                            &.up {
                                .transform(rotate(180deg));
                                .transition(all 300ms ease-in-out);
                            }
                        }

                        &.sub-button {
                            span {
                                padding-right: 29px;
                                background: transparent url("../img/sub/sub-arrow.png") no-repeat 100% center;
                            }
                        }
                    }

                    &+li {
                        & > a {
                            border-left: 1px solid #e2e2e2;
                        }
                    }

                    // 서브 메뉴
                    & > ul {
                        display: none;
                        position: absolute;
                        top: 61px;
                        left: 0;
                        right: 0;
                        padding: 5px 0;
                        background-color: #eeeeee;

                        li {
                            display: block;
                            a {
                                .base-font();
                                display: block;
                                background-color: #eeeeee;
                                padding: 8px 0 6px;
                                font-size: 14px;
                                color: #191919;
                            }
                        }
                    }
                }
            }
        }
    }
}

.page-down {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 73px;
    height: 73px;
    z-index: 1000;
    .transition(all 400ms ease-in-out);
    a {
        display: inline-block;
    }

    &.up {
        .transform(rotate(180deg));
        .transition(all 400ms ease-in-out);
    }
}

.slide-text {
    .base-font();
    padding-left: 10px;

    .wrap {
        .base-width();
        margin: auto;
    }

    &.white {
        .title {
            color: #fff;
        }
        .button {
            a {
                .base-font();
                color: #fff;
                border: 2px solid #fff;

                i.arrow {
                    background-image: url("/_res/ko/img/main/new/btn-right-arrow-w.png");
                }
            }
        }
    }

    .title {
        //margin-top: 70px;
        margin-top: 220px;
        p {
            font-size: 24px;
            line-height: 40px;
            letter-spacing: -2px;
            &.light {
                font-weight: 200;
            }
        }
    }
    .button {
        display: inline-block;
        //margin-top: 108px;
        margin-top: 30px;
        a {
            display: inline-block;
            text-align: center;
            width: 270px;
            border: 2px solid #000;
            padding: 18px 0 14px;
            font-weight: bold;
            font-size: 18px;
            & > * {
                vertical-align: top;
            }
            i.arrow {
                width: 11px;
                height: 19px;
                display: inline-block;
                margin-left: 20px;
                background: transparent url("/_res/ko/img/main/new/btn-right-arrow.png");
                background-size: 11px 19px;
            }
        }
    }
}

section[class^='cont'] {
    position: relative;
    overflow: hidden;
    //padding-top: 150px;
    .base-font();

    &.white {
        .title-wrap {
            .title {
                color: #fff;
            }
            .button {
                a {
                    color: #fff;
                    border: 2px solid #fff;

                    i.arrow {
                        background-image: url("/_res/ko/img/main/new/btn-right-arrow-w.png");
                    }
                }
            }
        }
    }

    .bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
    }

    .cont4-wrap {
        max-width: 1350px;
        min-width: 1100px;
        margin: auto;
        text-align: center;
    }

    .notice-wrap {
        //position: absolute;
        //top: 0;
        //left: 0;
        //right: 0;
        ////width: 1626px;
        //width: 100%;
        //.base-width();
        //margin: auto;
        //z-index: 20;
        //padding-left: 10px;
        //display: inline-block;

        display: inline-block;
        text-align: left;

        .title {
            //margin-top: 70px;
            display: inline-block;
            margin-top: 220px;
            font-size: 45px;
            font-weight: bold;
            padding-top: 13px;
            border-top: 2px solid #000;
        }
        .notice-more {
            display: inline-block;
            vertical-align: bottom;
            margin-bottom: 20px;
            margin-left: 44px;
        }
        .notices {
            height: 500px;
            overflow: hidden;
            ul {
                li {
                    position: relative;
                    padding-top: 10px;
                    margin-top: 40px;
                    width: 300px;
                    &:before {
                        content: ' ';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 50px;
                        height: 1px;
                        background: #000;
                    }

                    a {
                        font-size: 18px;
                        line-height: 27px;
                    }
                    p.date {
                        margin-top: 20px;
                        font-size: 16px;
                    }
                }
            }
        }
    }

    .news-wrap {
        //position: absolute;
        //top: 220px;
        //left: 42%;
        //right: 0;
        ////width: 1626px;
        //.base-width();
        //margin: auto;
        z-index: 10;
        display: inline-block;
        vertical-align: top;
        margin-left: 140px;
        text-align: left;

        .news-list {
            //margin-left: 666px;
            margin-top: 220px;
        }

        .news {

            &.left-img {
                .cont {
                    .desc {
                        p:nth-child(1) {
                            font-size: 18px;
                            font-weight: 700;
                        }
                    }
                }
            }

            &.right-img {
                margin-top: 55px;
                .cont {
                    display: inline-block;
                    width: 236px;
                    height: 179px;
                    vertical-align: top;
                    margin-left: 0;
                    .news-more {
                        margin-top: 40px;
                    }
                }
                .img {
                    margin-left: 47px;
                }
            }

            .img {
                display: inline-block;
                width: 236px;
                height: 179px;
                vertical-align: top;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .cont {
                display: inline-block;
                margin-left: 45px;

                .title {
                    display: inline-block;
                    padding-top: 10px;
                    border-top: 2px solid #000;
                    font-size: 25px;
                    font-weight: bold;
                }
                .desc {
                    margin-top: 17px;
                    font-size: 16px;
                    line-height: 26px;
                    font-weight: 300;
                }
                .news-more {
                    position: relative;
                    width: 112px;
                    height: 27px;
                    .box-sizing();
                    margin-top: 17px;
                    display: inline-block;
                    border: 1px solid #000;
                    padding: 6px 9px;
                    font-size: 12px;
                }
                i.news-plus {
                    position: absolute;
                    top: 7px;
                    right: 9px;
                    display: inline-block;
                    width: 9px;
                    height: 9px;
                    background: transparent url("/_res/ko/img/main/new/plus.png") no-repeat;
                }
            }

        }

        .banners {
            position: relative;
            display: inline-block;
            margin-top: 40px;

            .prev {
                position: absolute;
                z-index: 20;
                top: 50%;
                margin-top: -9.5px;
                left: 30px;
            }
            .next {
                position: absolute;
                z-index: 20;
                top: 50%;
                margin-top: -9.5px;
                right: 30px;
            }
            .list {
                position: relative;
                z-index: 10;
                //width: 562px;
                //height: 176px;
                width: 522px;
                height: 146px;
                .item {
                    position: absolute;
                    left: 0;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    width: 100%;
                    height: 100%;
                    & + .item {
                        display: none;
                    }
                }
            }
        }
    }

    .title-wrap {
        position: absolute;
        left: 0;
        right: 0;
        //width: 1626px;
        .base-width();
        margin: auto;
        z-index: 20;
        padding-left: 10px;
        .title {
            //margin-top: 70px;
            margin-top: 220px;
            p {
                font-size: 32px;
                line-height: 50px;
                letter-spacing: -2px;
                &.light {
                    font-weight: 200;
                }
            }
        }
        .button {
            display: inline-block;
            margin-top: 108px;
            a {
                display: inline-block;
                text-align: center;
                width: 270px;
                border: 2px solid #000;
                padding: 18px 0 14px;
                font-weight: bold;
                font-size: 18px;
                & > * {
                    vertical-align: top;
                }
                i.arrow {
                    width: 11px;
                    height: 19px;
                    display: inline-block;
                    margin-left: 20px;
                    background: transparent url("/_res/ko/img/main/new/btn-right-arrow.png");
                    background-size: 11px 19px;
                }
            }
        }
    }

    .flexslider {
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 10;

        .slide-item {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            //background-color: transparent;
            //background-repeat: no-repeat;
            //background-position: center;
            //background-size: cover;

            & + .slide-item {
                display: none;
            }
        }

        .flex-pauseplay {
            position: absolute;
            bottom: 51px;
            z-index: 10;
        }
        .flex-pauseplay a {
            display: inline-block;
            width: 12px;
            height: 12px;

            &.flex-play {
                background: transparent url(/_res/ko/img/main/new/btn-play.png) no-repeat center;
                background-size: 12px;
            }
            &.flex-pause {
                background: transparent url(/_res/ko/img/main/new/btn-pause.png) no-repeat center;
                background-size: 9px 12px;
            }

            text-indent: -9999px;
        }
        .flex-control-nav {
            width: 100%;
            position: absolute;
            bottom: 50px;
            text-align: center;
            margin-left: -25px;
            z-index: 10;
        }
        .flex-control-nav li {
            margin: 0 6px;
            display: inline-block;
            zoom: 1;
            *display: inline;
        }
        .flex-control-paging li a {
            width: 11px;
            height: 11px;
            display: block;
            //background: #666;
            //background: rgba(0, 0, 0, 0.5);
            border: 1px solid #fff;
            cursor: pointer;
            text-indent: -9999px;
            .border-radius(50%);
        }
        .flex-control-paging li a:hover {
            background: #fff;
            //background: rgba(0, 0, 0, 0.7);
        }
        .flex-control-paging li a.flex-active {
            background: #fff;
            //background: rgba(0, 0, 0, 0.9);
            cursor: default;
        }

        .flex-direction-nav {
            display: none;
        }
    }
}

section.footer {
    position: relative;
    //overflow: hidden;
    .base-font();
    height: 203px;
    color: #222;
    background: #e2e2e2;
    font-size: 15px;
    font-weight: 300;
    padding-top: 85px;
    border-top: 1px solid #e2e2e2;
    .box-sizing();

    .footer-wrap {
        width: 1460px;
        margin: auto;
        text-align: left;
        .float-clear();
        .item {
            float: left;
            vertical-align: middle;
            padding: 0 25px;
            .box-sizing();

            .select-type2 {
                display: inline-block;
                width: 127px;
                margin-top: -10px;
                text-align: left;
                background: #fff;
                .border-radius(0);
                .selected {
                    font-size: 14px;
                    background: #fff;
                }
                .list {
                    width: 127px !important;
                    .border-radius(0);
                }

                &+span {
                    margin-left: 25px;
                }
            }

            &+.item {
                border-left: 1px solid #c6c6c6;
            }

            //&:nth-child(1) {
            //    width: 115px;
            //    border-right: 1px solid #c6c6c6;
            //}
            //&:nth-child(2) {
            //    width: 152px;
            //    border-right: 1px solid #c6c6c6;
            //    padding-left: 15px;
            //}
            //&:nth-child(3) {
            //    width: 163px;
            //    border-right: 1px solid #c6c6c6;
            //    padding-left: 45px;
            //}
            //&:nth-child(4) {
            //    width: 290px;
            //    text-align: center;
            //}
            //&:nth-child(5) {
            //    width: 255px;
            //    border-right: 1px solid #c6c6c6;
            //    padding-left: 20px;
            //}
            //&:nth-child(6) {
            //    width: 203px;
            //    border-right: 1px solid #c6c6c6;
            //}
        }
    }
}

.btn-lang {
    .base-font();
    display: inline-block;
    border: 1px solid #aaaaaa;
    .border-radius(8px);
    padding: 5px 8px;
    color: #222222;
    font-size: 12px;
}

/* common : select */
.select-type1 {
    .base-font();
    position: relative;
    border: 1px solid #aaaaaa;
    .border-radius(8px);

    &.up {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    font-size: 12px;

    //background: transparent url("/_res/ko/img/main/new/ic-arrowdown.svg") no-repeat 95% center;

    select {
        display: none;
    }
    .selected {
        position: relative;
        padding: 5px 8px;
        cursor: pointer;
        color: #222222;
        &:before {
            content: '';
            position: absolute;
            top: 9px;
            right: 12px;
            width: 7px;
            height: 4px;
            background: transparent url("/_res/ko/img/main/new/selectarrow.png") no-repeat center;
        }
        &.up:before {
            .transform(rotate(180deg));
        }
    }
    .list {
        display: none;
        position: absolute;
        z-index: 1000;
        background-color: #fff;
        top: 0;
        left: 0;

        border-left: 1px solid #aaaaaa;
        border-right: 1px solid #aaaaaa;
        border-bottom: 1px solid #aaaaaa;

        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;

        ul {
            li {
                padding: 6px 9px;
                //border-top: 1px solid #f0f0f0;
                cursor: pointer;
                font-size: 11px;
                color: #222222;

                &:hover {
                    background-color: #f5f5f5;
                }
                &:last-of-type {
                    border-bottom-left-radius: 8px;
                    border-bottom-right-radius: 8px;
                }
            }
        }
    }
}

.select-type2 {
    .base-font();
    position: relative;
    border: 1px solid #aaaaaa;
    .border-radius(8px);

    &.up {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    font-size: 12px;

    //background: transparent url("/_res/ko/img/main/new/ic-arrowdown.svg") no-repeat 95% center;

    select {
        display: none;
    }
    .selected {
        position: relative;
        padding: 5px 8px;
        cursor: pointer;
        color: #222222;
        &:before {
            content: '';
            position: absolute;
            top: 9px;
            right: 12px;
            width: 7px;
            height: 4px;
            background: transparent url("/_res/ko/img/main/new/selectarrow.png") no-repeat center;
        }
        &.up:before {
            .transform(rotate(180deg));
        }
    }
    .list {
        display: none;
        position: absolute;
        z-index: 1000;
        background-color: #fff;
        top: 0;
        left: 0;

        //border-top: 1px solid #aaaaaa;
        //border-left: 1px solid #aaaaaa;
        //border-right: 1px solid #aaaaaa;
        //border-bottom: 1px solid #aaaaaa;
        border: 1px solid #aaa;

        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;

        ul {
            li {
                padding: 6px 9px;
                //border-top: 1px solid #aaaaaa;
                cursor: pointer;
                font-size: 11px;
                color: #222222;

                &:hover {
                    background-color: #f5f5f5;
                }
                &:last-of-type {
                    border-bottom-left-radius: 8px;
                    border-bottom-right-radius: 8px;
                }
            }
        }
    }
}

.sub-location {
    position: relative;
    z-index: -1;
    .wrap {
        .sub-width();
        margin: auto;
        font-size: 12px;
        text-align: right;
        padding: 25px 0;
        &  > * {
            vertical-align: middle;
        }

        .jwxe_navigator {
            display: inline-block;
            margin-left: 5px !important;
        }
    }
}

// 뉴스 왼쪽 마진
@media screen and (max-width: 1245px) {
    .news-wrap {
        text-align: right;
        left: initial;
        .news-list {
            display: inline-block;
            //margin-left: 47% !important;
            margin-left: 0 !important;
            margin-right: 10px;
            text-align: left;
        }
    }
}